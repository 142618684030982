



















import { Component, Inject, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import Project from '@/shared/modules/project/models/project.model'
import Tab from '@/shared/classes/tab'
import can from '@/shared/helpers/can.helper'
import { CompanyRoutes } from '@/shared/router/company'

@Component({
  methods: { can, __ },
})
export default class Summary extends Vue {
  tabs: Tab[] = []

  created() {
    this.tabs = [
      new Tab()
        .setIcon('mdi-list-status')
        .setTitle(__('company.views.projects.show.tabs.summary.instructions.title'))
        .setTo(CompanyRoutes.projectsShowTabSummaryTabInstructionsAssigned)
        .setActiveCondition(() => {
          return [ CompanyRoutes.projectsShowTabSummaryTabInstructionsAssigned as string, CompanyRoutes.projectsShowTabSummaryTabInstructionsTemplates as string ].includes(this.$route.name as string)
        })
        .setPermissions([]),
      new Tab()
        .setIcon('mdi-chart-donut')
        .setTitle(__('company.views.projects.show.tabs.summary.summary.title'))
        .setTo(CompanyRoutes.projectsShowTabSummaryTabSummaryGeneration)
        .setActiveCondition(() => {
          return CompanyRoutes.projectsShowTabSummaryTabSummaryGeneration === this.$route.name as string
        })
        .setPermissions([]),
    ]
  }
}
